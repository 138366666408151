import Home from "@/pages/Home.vue";
import Login from "@/pages/Login.vue";
import {createRouter, createWebHistory} from "vue-router";
import History from "@/pages/History.vue";
import Signup from "@/pages/Signup.vue";
import Admin from "@/pages/Admin.vue";
import AdminOutfit from "@/pages/AdminOutfit.vue";
import AdminMember from "@/pages/AdminMember.vue";
import AdminMemberInfo from "@/pages/AdminMemberInfo.vue";
import MyPage from "@/pages/MyPage.vue";
import ChangeInfo from "@/pages/ChangeInfo.vue";
import WithdrawMember from "@/pages/WithdrawMember.vue";
import AdminItem from "@/pages/AdminItem.vue";

const routes = [
    {path: "/", component: Home},
    {path: "/login", component: Login},
    {path: "/history", component: History},
    {path: "/signup", component: Signup},
    {path: "/admin", component: Admin},
    {path: "/adminOutfit", component: AdminOutfit},
    {path: "/adminMember", component: AdminMember},
    {path: "/adminMemberInfo/:email", name: "AdminMemberInfo", component: AdminMemberInfo},
    {path: "/myPage", component: MyPage},
    {path: "/changeInfo", component: ChangeInfo},
    {path: "/withdrawMember", component: WithdrawMember},
    {path: "/adminItem", component: AdminItem}
]

const router = createRouter({
    history: createWebHistory(),
    routes: routes
})

export default router;