<template>
  <div class="write">
    <div class="container modal-overlay">
      <div class="modal-content">
        <main>
          <span @click="closeModal"><i class="fa fa-times close-button"></i></span>
          <!-- 외투 -->
          <section>
            <div class="write-text"><i class="bi bi-dice-1"></i> OVERCOAT</div>
            <div class="button-group">
              <button
                  v-for="item in state.overcoatItems"
                  :key="item.id"
                  @click="toggleSelection('selectedOvercoat', item.name)"
                  :class="{ selectedOvercoat: isSelected('selectedOvercoat', item.name) }"
              >
                {{ item.name }}
              </button>
            </div>
          </section>

          <!-- 상의 -->
          <section>
            <div class="write-text"><i class="bi bi-dice-2"></i> TOP</div>
            <div class="button-group">
              <button
                  v-for="item in state.topItems"
                  :key="item.id"
                  @click="toggleSelection('selectedTop', item.name)"
                  :class="{ selectedTop: isSelected('selectedTop', item.name) }"
              >
                {{ item.name }}
              </button>
            </div>
          </section>

          <!-- 하의 -->
          <section>
            <div class="write-text"><i class="bi bi-dice-3"></i> BOTTOM</div>
            <div class="button-group">
              <button
                  v-for="item in state.bottomItems"
                  :key="item.id"
                  @click="toggleSelection('seletedBottom', item.name)"
                  :class="{ selectedBottom: isSelected('seletedBottom', item.name) }"
              >
                {{ item.name }}
              </button>
            </div>
          </section>

          <!-- 액세서리 -->
          <section>
            <div class="write-text"><i class="bi bi-dice-4"></i> ACC</div>
            <div class="button-group">
              <button
                  v-for="item in state.accessoryItems"
                  :key="item.id"
                  @click="toggleSelection('seletedAcc', item.name)"
                  :class="{ selectedAcc: isSelected('seletedAcc', item.name) }"
              >
                {{ item.name }}
              </button>
            </div>
          </section>

          <hr>
          <!-- 리뷰 버튼들 -->
          <section>
            <div class="button-group-review">
              <button
                  @click="toggleSelectionOne('좋아요')"
                  :class="{ selectedGood: isSelectedOne('좋아요') }"
              >
                좋아요
              </button>
              <button
                  @click="toggleSelectionOne('추워요')"
                  :class="{ selectedCold: isSelectedOne('추워요') }"
              >
                추워요
              </button>
              <button
                  @click="toggleSelectionOne('더워요')"
                  :class="{ selectedHot: isSelectedOne('더워요') }"
              >
                더워요
              </button>
            </div>
          </section>

          <!-- 제출 버튼 -->
          <div class="submit-button-container">
            <button class="submit-button" @click="submit">작성하기</button>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import {reactive} from "vue";
import axios from "axios";

export default {
  props: {
    address: {
      type: Object,
      required: false
    }
  },
  setup(props, {emit}) {
    const state = reactive({
      overcoatItems: [],
      topItems: [],
      bottomItems: [],
      accessoryItems: [],
      selectedOvercoat: [],
      selectedTop: [],
      seletedBottom: [],
      seletedAcc: [],
      form: {
        overcoat: "",
        top: "",
        bottom: "",
        accessory: "",
        review: "",
      },
    });

    axios.get("/api/outfit/items").then(({data}) => {
      state.overcoatItems = data.filter(item => item.category === 1);
      state.topItems = data.filter(item => item.category === 2);
      state.bottomItems = data.filter(item => item.category === 3);
      state.accessoryItems = data.filter(item => item.category === 4);

    }).catch(error => {
      console.error("데이터를 불러오는 중 에러 발생:", error);

    });

    const submit = () => {
      const args = JSON.parse(JSON.stringify(state.form));
      args.overcoat = JSON.stringify(state.selectedOvercoat);
      args.top = JSON.stringify(state.selectedTop);
      args.bottom = JSON.stringify(state.seletedBottom);
      args.accessory = JSON.stringify(state.seletedAcc);
      args.address = props.address?.city || "알수없음";

      axios.post("/api/outfit/write", args)
          .then(() => {
            alert("리뷰 작성 완료!");

            emit(`sendClose`);

            location.reload();
          })
          .catch(error => {
            console.error("작성 중 에러 발생:", error);
          });
    };

    //다중 선택
    const toggleSelection = (category, itemName) => {
      const index = state[category].indexOf(itemName);
      if (index === -1) {
        state[category].push(itemName);
      } else {
        state[category].splice(index, 1); //요소를 제거
      }
    };

    //다중 선택
    const isSelected = (category, itemName) => {
      return state[category].includes(itemName);
    }

    //단일 선택
    const toggleSelectionOne = (how) => {
      state.form.review = state.form.review === how ? "" : how;
    }

    //단일 선택
    const isSelectedOne = (how) => {
      return state.form.review === how;
    };

    const closeModal = () => {
      emit('sendClose');
    }

    return {state, submit, toggleSelection, isSelected, closeModal, isSelectedOne, toggleSelectionOne};
  }
}
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw; /* 화면 전체 너비 */
  height: 100vh; /* 화면 전체 높이 */
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: hidden;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  position: relative;
}

.write-text {
  font-size: 14px;
  font-weight: bold;
  margin: 7px 0 2px 6px;
  color: #635E4E;
}

button {
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #C7C6C4;
  background-color: #F6F5F2;
  color: #635E4E;
  cursor: pointer;
  font-size: 0.78em;
  transition: background-color 0.3s;
  margin: 3px;
  font-weight: bold;
}

/* 선택된 상태별 색상 정의 */
.button-group button.selectedOvercoat {
  background-color: #EFBC9B;
  border: 1px solid #EFBC9B;
  color: black;
}

.button-group button.selectedTop {
  background-color: #FBF3D5;
  border: 1px solid #FBF3D5;
  color: black;
}

.button-group button.selectedBottom {
  background-color: #D6DAC8;
  border: 1px solid #D6DAC8;
  color: black;
}

.button-group button.selectedAcc {
  background-color: #9CAFAA;
  border: 1px solid #9CAFAA;
  color: black;
}

.button-group {
  display: flex;
  flex-wrap: wrap;
}

.button-group-review {
  display: flex;
  justify-content: space-between;
}

/* 리뷰 버튼 그룹 */
.button-group-review button {
  padding: 8px 21px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.875em;
  background-color: #635E4E;
  color: white;
  margin: 0 1px;
}

.button-group-review button.selectedGood {
  background-color: #007D50;
}

.button-group-review button.selectedCold {
  background-color: #0871B1;
}

.button-group-review button.selectedHot {
  background-color: #EB5353;
}

/* 작성하기 버튼 컨테이너 */
.submit-button-container {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.submit-button {
  padding: 12px 30px;
  background-color: #635E4E;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.875em;
}

.submit-button:hover {
  background-color: #B0AB99;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  color: #333;
  font-size: 1.2em;
  cursor: pointer;
}

section {
  margin-bottom: 10px;
}
</style>